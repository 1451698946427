.estatisticas-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .estatisticas-tabela {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .estatisticas-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .estatisticas-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .estatisticas-label {
    font-weight: bold;
  }
  
  .vitorias {
    color: green;
    font-weight: bold;
  }
  
  .derrotas {
    color: red;
    font-weight: bold;
  }
  
  @media (max-width: 600px) {
    .estatisticas-tabela {
      grid-template-columns: 1fr;
    }
  
    .estatisticas-card {
      padding: 20px;
    }
  }
  