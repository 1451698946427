.player-lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
  padding: 10px;
}

@media (min-width: 768px) {
  .player-lists {
    flex-direction: row;
    justify-content: space-between;
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-image: url('../../../public/background_app.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.list {
  /* background-color: #f0f4f8; */
  background-image: url('../../../public/background_app.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.list:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

h2 {
  color: #ffff;
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
  transition: box-shadow 0.3s;
}

li:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

li span {
  display: flex;
  flex-direction: column;
}

li span p {
  margin: 0;
  font-size: 0.9rem;
  color: #e74c3c;
}

.divButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  align-items: center;
  flex-direction: column;
}

button {
  background-color: #5e176a;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 15px;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.arrival-button {
  background-color: #2ecc71;
}

.arrival-button:hover {
  background-color: #27ae60;
}

.remove-button {
  background-color: #e74c3c;
}

.remove-button:hover {
  background-color: #c0392b;
}