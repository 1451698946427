.player-card {
    background-color: #393939;
    color: #0f0f0f;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    text-align: center;
    width: 310px;
    height: 480px;
    background-image: url('../../../public/fundo_player.png'); /* Caminho para a imagem de fundo */
    background-size: cover; /* Redimensiona a imagem para cobrir o contêiner */
    background-repeat: no-repeat; /* Evita repetição da imagem */
    position: relative;
    max-width: 300px;
    /* height: auto; Altura ajustada automaticamente para manter a proporção */
  }
  
  .player-image img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: 3px solid #f79e02;
    margin-bottom: 10px;
  }
  
  .player-info h3 {
    font-size: 18px;
    margin: 0;
    text-transform: uppercase;
  }

  .player-info h4 {
    font-size: 18px;
    margin: 0;
    padding: 1px;
    text-transform: uppercase;
  }
  
  .player-info p {
    font-size: 15px;
    margin: 5px 0;
    margin-bottom: 5px;
    text-transform: uppercase;
}

  .player-info {
    margin-bottom: 47px;
    margin-right: 63px;
    color: black;
    text-transform: uppercase;
    text-align: end;
}
  
  /* Estilos adicionais para simular o card FIFA */
  .player-card::before {
    content: '';
    width: 100%;
    height: 10px;
    /* background-color: #f79e02; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }

.player-image {
  width: 100px; /* Largura da imagem do jogador */
  height: auto; /* Altura ajustada automaticamente para manter a proporção */
  margin-right: 144px; /* Espaçamento à direita */
  margin-bottom: 200px; 
  position: absolute; /* Adicione esta linha para posicionar absolutamente */
  top: 50px; /* Ajuste conforme necessário para a posição vertical */
}

.player-time-image {
  width: 50px; /* Largura da imagem do jogador */
  height: auto; /* Altura ajustada automaticamente para manter a proporção */
  margin-right: 235px; /* Espaçamento à direita */
  margin-bottom: 500px; 
  position: absolute; /* Adicione esta linha para posicionar absolutamente */
  top: 110px; /* Ajuste conforme necessário para a posição vertical */
}

.player-time-image img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 3px solid #f79e02;
  margin-bottom: 10px;
}

.player-pais-image {
  width: 50px; /* Largura da imagem do jogador */
  height: auto; /* Altura ajustada automaticamente para manter a proporção */
  margin-right: 235px; /* Espaçamento à direita */
  margin-bottom: 500px; 
  position: absolute; /* Adicione esta linha para posicionar absolutamente */
  top: 80px; /* Ajuste conforme necessário para a posição vertical */
}

.player-pais-image img {
  /* border-radius: 50%; */
  width: 60px;
  height: 40px;
  object-fit: cover;
  /* border: 3px solid #f79e02; */
  margin-bottom: 10px;
}

.custom-card {
    /* Estilos existentes ... */
  
    /* Posiciona a imagem do jogador à direita e abaixo */
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  